import React, { createContext, useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";

const defaultState = {
  cart: [],
  isProductInCart: () => {},
  addProductToCart: () => {},
  removeProductFromCart: () => {},
  totalProductsInCart: () => {},
  search: "",
  setSearchValue: () => {},
  products: [],
  setProducts: () => {},
  productsToDisplay: [],
  setProductsToDisplay: () => {},
};

export const AppContext = createContext(defaultState);

export const AppProvider = ({ children }) => {
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")) || []);
  const [products, setProducts] = useState([]);
  const [totalProductsInCart, setTotalProductsInCart] = useState(cart.length || 0);
  const [search, setSearch] = useState("");
  const [productsToDisplay, setProductsToDisplay] = useState([]);

  const data = useStaticQuery(graphql`
    query CartProductById {
      products: allStripeProduct {
        edges {
          node {
            name
            price
            description
            images
            id
            default_price
            selectMultiple
          }
        }
      }
    }
  `);

  useEffect(() => {
    setProducts(data.products.edges);
  }, []);

  const setSearchValue = value => {
    setSearch(value);
  };

  useEffect(() => {
    // console.log("PRODUCTS: ", products);
  }, [products]);

  useEffect(() => {
    setTotalProductsInCart(cart.length);
  }, [cart]);

  useEffect(() => {
    // console.log("SEARCHING")
  }, [search]);

  const addProductToCart = (id, quantity) => {
    let newCart = [];
    let cancel = false;

    cart.forEach(item => {
      newCart.push(item);

      if (item === id) {
        cancel = true;
      }
    });
    if (!cancel) {
      newCart.push({ id, quantity });
    }

    localStorage.setItem("cart", JSON.stringify(newCart));
    setCart(newCart);
    setTotalProductsInCart(newCart.length);
  };

  const removeProductFromCart = id => {
    let newCart = [];

    cart.forEach(item => {
      if (item.id != id) {
        newCart.push(item);
      }
    });

    localStorage.setItem("cart", JSON.stringify(newCart));
    setCart(newCart);
    setTotalProductsInCart(newCart.length);
  };

  const resetCart = () => {
    let newCart = [];

    localStorage.setItem("cart", JSON.stringify(newCart));
    setCart(newCart);
    setTotalProductsInCart(newCart.length);
  };

  const emptyCart = () => {
    let newCart = [];

    localStorage.setItem("cart", JSON.stringify(newCart));
    setCart(newCart);
    setTotalProductsInCart(newCart.length);
  };

  const isProductInCart = id => {
    let value = false;

    cart.forEach(item => {
      if (item.id == id) {
        value = true;
      }
    });

    return value;
  };

  const getItemById = id => {
    let item = [];

    for (let i = 0; i < data.products.edges.length; i++) {
      const product = data.products.edges[i];

      if (product.node.id == id) {
        item = product;
      }
    }

    return item;
  };

  const updateQuantity = (id, quantity) => {
    let newCart = [];

    cart.forEach(item => {
      if (item.id != id) {
        newCart.push(item);
      } else if (item.id == id) {
        newCart.push({id, quantity})
      }
    });

    localStorage.setItem("cart", JSON.stringify(newCart));
    setCart(newCart);
    setTotalProductsInCart(newCart.length);
  };

  return (
    <AppContext.Provider
      value={{
        cart,
        emptyCart,
        isProductInCart,
        addProductToCart,
        removeProductFromCart,
        totalProductsInCart,
        search,
        setSearchValue,
        products,
        setProducts,
        productsToDisplay,
        setProductsToDisplay,
        getItemById: id => getItemById(id),
        updateQuantity: updateQuantity,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
